<template>
	<div class="mdc-filter max-width">
		<div class="input-field max-width">
			<div class="mdc-text-field mdc-text-field--outlined mdc-text-field--with-leading-icon max-width"
			     data-mdc-auto-init="MDCTextField">
				<i class="material-icons mdc-text-field__icon">filter_alt</i>
				<input v-bind:id="htmlId" v-model="inputText" autocomplete="off" class="mdc-text-field__input max-width"
				       type="search">
				<div class="mdc-notched-outline">
					<div class="mdc-notched-outline__leading"></div>
					<div class="mdc-notched-outline__notch">
						<label class="mdc-floating-label" v-bind:for="htmlId">{{ title }}</label>
					</div>
					<div class="mdc-notched-outline__trailing"></div>
				</div>
			</div>
			<div v-if="description !== undefined" class="mdc-text-field-helper-line">
				<div id="username-helper-text" aria-hidden="true" class="mdc-text-field-helper-text">
					{{ description }}
				</div>
			</div>
		</div>
	</div>
</template>

<script>

// Material Design Components
// noinspection NpmUsedModulesInstalled
import autoInit from '@material/auto-init'

import {mapState} from 'vuex'

export default {
	name: 'FilterInput',
	components: {},
	props: [
		'description',
		'defaultValue'
	],
	data () {
		return {
			textInputEl: undefined,
			inputText: '',
			lastQuery: undefined,
			loading: true,
			title: 'Filter',
			timeoutRef: undefined
		}
	},
	computed: {
		...mapState([]),
		htmlId () {
			return 'mdc-filter-text'
		},
	},
	watch: {
		inputText () {
			if (this.timeoutRef !== undefined) {
				clearTimeout(this.timeoutRef)
			}
			this.timeoutRef = setTimeout(this.emitQuery, 250)
		}
	},
	created () {
		if (this.defaultValue !== undefined && this.defaultValue !== null) {
			this.inputText = this.defaultValue
		}
	},
	mounted () {
		autoInit()  // Material Design Components
		this.textInputEl = document.getElementById(this.htmlId)
		window.addEventListener('keypress', this.handleKeypress)
		setTimeout(() => {
			this.$nextTick(() => {
				this.textInputEl.focus()
			})
		}, 100)
	},
	updated () {
		autoInit()  // Material Design Components
	},
	beforeDestroy () {
		window.removeEventListener('keypress', this.handleKeypress)
	},
	methods: {
		handleKeypress (event) {
			// if (this.timeoutRef !== undefined) {
			// 	clearTimeout(this.timeoutRef)
			// }
			// if (event.code === "Enter" || event.code === "NumpadEnter") {
			// 	this.emitQuery();
			// } else {
			// 	this.timeoutRef = setTimeout(this.emitQuery, 250)
			// }
		},
		emitQuery () {
			this.$nextTick(() => {
				if (this.inputText !== this.lastQuery) {
					let query = {
						query: this.inputText.trim(),
					}
					this.$emit('new-query', query)
					this.$set(this, 'lastQuery', query.query)
				}
			})
		}
	}
}

</script>

<style lang="scss" scoped>

.mdc-filter {
	display: flex;
	flex-direction: row;
	flex: 0 1 auto;
	align-items: flex-start;
}

.max-width {
	width: 100%;
}

.mdc-filter .input-field {
	flex: 0 1 auto;
}

.mdc-filter .mdc-text-field {
	background-color: white;
}

.mdc-filter button[type=submit] {
	visibility: hidden;
	display: none;
}

</style>
