<template>
	<div class="tree-list">
		<div v-show="treeData !== undefined && treeData.length > 0"
			 class="tree-container"
		>
			<div v-for="n in numberLists"
				 v-bind:id="htmlScrollId(n)"
				 class="scrolling-item"
				 v-on:mouseenter="handleScroll(n)"
				 v-on:scroll="handleScroll(n)"
			>
				<div class="max-width scroll-button scroll-button-top scroll-button-hidden" style="top: 0;">
					<button class="mdc-button max-width" v-on:click="scrollUp(n)">
						<div class="mdc-button__ripple"></div>
						<i aria-hidden="true" class="material-icons mdc-button__icon">expand_less</i>
						<span class="mdc-button__label"></span>
					</button>
				</div>
				<div>
					<TreeListInner
						v-bind:listData="dataForList(n)"
						v-bind:listHeight="listHeight"
						v-on:tree-list-click="clickHandler(n, $event)"
					/>
				</div>
				<div class="max-width scroll-button scroll-button-bottom scroll-button-hidden" style="bottom: 0;">
					<button class="mdc-button max-width" v-on:click="scrollDown(n)">
						<div class="mdc-button__ripple"></div>
						<i aria-hidden="true" class="material-icons mdc-button__icon">expand_more</i>
						<span class="mdc-button__label"></span>
					</button>
				</div>
			</div>
		</div>
		<div v-if="showDebug && debugInfo !== undefined" class="debug-box">
			<pre>{{ debugInfo }}</pre>
		</div>
	</div>
</template>

<script>

import TreeListInner from './TreeListInner'

const itemHeight = 74
const itemWidth = 240

export default {
	components: {
		TreeListInner
	},
	props: [
		'treeData',
		'parentSelected'
	],
	data () {
		return {
			uid: undefined,
			selected: [],

			appBarElement: undefined,
			containerElement: undefined,
			intersectionObserver: undefined,
			mutationObserver: undefined,

			showDebug: false,
			debugInfo: undefined,

		}
	},
	watch: {
		parentSelected (value) {
			// console.debug('parentSelected changed', value)
			if (value !== undefined && value.length >= 2) {
				this.clickHandler(1, value)
			} else {
				this.$nextTick(() => {
					this.clickHandler(1, this.selected[0])
				})
			}
		},
		// treeData(value) {
		//     console.debug('treeData changed');
		// },
	},
	computed: {
		maxNumberLists () {
			return this.countDepth(this.treeData) - 1
		},
		numberLists () {
			if (this.treeData === undefined) {
				console.debug('numberLists', 'tree=' + undefined)
				return 0
			}
			if (this.selected.length === 0) {
				// console.debug("numberLists", "selected=" + this.selected)
				return 1
			}

			let list = this.selectedSublistFor(this.selected.length)

			// if (list !== undefined) {
			//     console.debug("numberLists", "selected=" + this.selected.length, "list=" + list.length)
			// } else {
			//     console.debug("numberLists", "selected=" + this.selected.length, "list=" + undefined)
			// }

			if (list !== undefined && list.length >= 1) {
				return this.selected.length + 1
			} else {
				return this.selected.length
			}
		},
		maxListItems () {
			if (this.treeData === undefined) {
				return 0
			}
			return this.findMaxItems(this.treeData)
		},
		listHeight () {
			return this.maxListItems * itemHeight + 'px'
		}
	},
	created () {
		this.uid = (new Date()).getTime()
	},
	mounted () {
		this.appBarElement = document.getElementById('app-bar')
		this.containerElement = this.$el.children[0]

		addEventListener('scroll', this.adjustPositions, {passive: true})
		addEventListener('resize', this.adjustPositions, {passive: true})
		this.$nextTick(this.adjustPositions)
	},
	beforeDestroyed () {
		removeEventListener('resize', this.adjustPositions)
		removeEventListener('scroll', this.adjustPositions)
	},
	updated () {
		this.$nextTick(this.adjustPositions)
	},
	methods: {
		countDepth (list) {
			let self = this
			if (list === undefined) {
				return 0
			}
			let depth = 0
			list.forEach(item => {
				if (item.list !== undefined) {
					let itemDepth = self.countDepth(item.list)
					if (itemDepth > depth) {
						depth = itemDepth
					}
				}
			})
			return depth + 1
		},
		findMaxItems (list) {
			let self = this
			if (list === undefined) {
				return 0
			}
			let items = list.length
			list.forEach(item => {
				if (item.list !== undefined) {
					let itemItems = self.findMaxItems(item.list)
					if (itemItems > items) {
						items = itemItems
					}
				}
			})
			return items
		},
		clickHandler (n, id) {
			let i = n - 1
			// console.debug("selected", i, "=", id)
			this.$set(this.selected, i, id)
			this.$set(this, 'selected', this.selected.slice(0, i + 1))

			this.$nextTick(() => {
				if (i <= 0) {
					this.containerElement.scrollLeft = 0
				} else if (i > 0) {
					this.containerElement.scrollLeft = itemWidth * i
				}
			})
			this.$emit('tree-list-click', this.selected)
		},
		selectedSublistFor (n) {
			let i = n - 1
			if (i < 0) {
				return undefined
			}
			let list = []
			if (i === 0) {
				list = this.treeData
			} else {
				list = this.selectedSublistFor(n - 1)
			}
			if (list === undefined) {
				return undefined
			}
			if (this.selected[i] !== undefined) {
				for (let j = 0; j < list.length; j++) {
					if (list[j].id === this.selected[i]) {
						return list[j].list
					}
				}
			}
			return undefined
		},
		dataForList (n) {
			if (this.treeData === undefined) {
				return undefined
			}
			let i = n - 1
			let list = []
			if (i === 0) {
				list = this.treeData
			} else {
				list = this.selectedSublistFor(n - 1)
			}
			if (list === undefined) {
				return undefined
			}
			let items = []
			for (let j = 0; j < list.length; j++) {
				let item = {
					id: list[j].id,
					icon: list[j].icon,
					title: list[j].title,
					text: list[j].text,
					active: list[j].id === this.selected[i],
					hidden: false,
					pending: false,
				}
				if (list[j].hidden !== undefined && typeof list[j].hidden === 'boolean') {
					item.hidden = list[j].hidden
				}
				if (!item.hidden && list[j].active && this.selected[i] === undefined) {
					this.$set(this.selected, i, list[j].id)
				}
				if (list[j].pending !== undefined && typeof list[j].pending === 'boolean') {
					item.pending = list[j].pending
				}
				if (item.active && item.hidden) {
					item.active = false
					this.$set(this.selected, i, undefined)
					this.$set(this, 'selected', this.selected.slice(0, i))
				}
				items.push(item)
			}
			// console.debug("dataForList", n, items)
			return items
		},
		htmlScrollId (n) {
			return 'scrolling-item-' + this.uid + '-' + n
		},
		adjustPositions (again = true) {
			this.adjustContainerPosition()
			for (let n = 1; n <= this.maxNumberLists; n++) {
				this.handleScroll(n)
			}
			if (again) {
				this.$nextTick(() => {
					this.adjustPositions(false)
				})
			}
		},
		adjustContainerPosition () {

			const verticalMargin = 16

			let mainBounding = this.$el.getBoundingClientRect()

			//
			// Top
			//

			const topBarHeight = 64
			let targetTop = topBarHeight + verticalMargin + this.appBarElement.offsetTop
			if (targetTop < verticalMargin) {
				targetTop = verticalMargin
			}
			this.containerElement.style.top = targetTop + 'px'
			if (mainBounding.top > targetTop) {
				// move the list back to being inline in the page content
				this.containerElement.classList.remove('sticky-fixed')
				this.containerElement.classList.add('sticky-relative')
				// this.containerElement.style.width = 'auto'
			} else {
				// move the list to be fixed just below the top app bar
				this.containerElement.classList.remove('sticky-relative')
				this.containerElement.classList.add('sticky-fixed')
			}

			//
			// Width
			//

			this.containerElement.style.width = mainBounding.width + 'px'

			//
			// Height
			//

			// This code is about bringing up the bottom of the list when we have
			// content which we need to display below it. This requires we walk
			// up the dom to find the height of a parent element.
			//
			const numberParentsToSkip = 1
			let windowHeight = window.innerHeight
			let parentHeight = windowHeight
			let parent = this.$el
			for (let i = 0; i <= numberParentsToSkip; i++) {
				if (parent === undefined || parent === null) {
					break
				}
				parent = parent.parentElement
			}
			if (parent !== undefined && parent != null) {
				let parentBounds = parent.getBoundingClientRect()
				parentHeight = parentBounds.height + parentBounds.top
				if (parentHeight > windowHeight) {
					parentHeight = windowHeight
				}
			}

			let actualBounds = this.containerElement.getBoundingClientRect()
			let targetHeight = parentHeight - actualBounds.top - verticalMargin
			if (targetHeight < 500) {
				targetHeight = 500
			}
			this.containerElement.style.height = targetHeight + 'px'

			//
			// Debug
			//
			if (this.showDebug) {
				this.debugInfo = JSON.stringify({
					classes: this.containerElement.classList,
					targetTop: targetTop,
					targetHeight: targetHeight,
					actualTop: actualBounds.top,
					actualHeight: actualBounds.height,
					mainHeight: mainBounding.height,
					containerHeight: this.containerElement.clientHeight,
					docHeight: document.body.clientHeight,
					winHeight: window.innerHeight,

				}, null, 2)
			}

		},
		handleScroll (n) {
			//
			// There are multiple lists (side by side)
			// This handles positioning for the list with index n.
			//

			const buttonHeight = 36

			let containerBounding = this.containerElement.getBoundingClientRect()
			if (this.containerElement === undefined || this.containerElement === null) {
				return
			}

			let containerTop = containerBounding.top
			let containerHeight = containerBounding.height

			let listElement = this.$el.querySelector('#' + this.htmlScrollId(n))
			if (listElement === undefined || listElement === null) {
				return
			}
			let btnTop = listElement.querySelector('.scroll-button-top')
			let btnBottom = listElement.querySelector('.scroll-button-bottom')

			let scrollPosition = listElement.scrollTop
			let scrollMax = listElement.scrollHeight - listElement.clientHeight

			// console.debug('handleScroll', n, 'scrollPosition', scrollPosition, 'scrollMax', scrollMax)

			listElement.style.height = containerHeight - 2 + 'px'

			// keep the buttons in the correct place
			btnTop.style.top = (containerTop + 1) + 'px'
			btnTop.style.width = listElement.clientWidth + 'px'

			btnBottom.style.top = (containerTop + containerHeight - buttonHeight - 1) + 'px'
			btnBottom.style.width = listElement.clientWidth + 'px'

			// show/hide the up button
			if (scrollPosition <= 0) {
				btnTop.classList.add('scroll-button-hidden')
			} else {
				btnTop.classList.remove('scroll-button-hidden')
			}

			// show/hide the down button
			if (scrollPosition >= scrollMax) {
				btnBottom.classList.add('scroll-button-hidden')
			} else {
				btnBottom.classList.remove('scroll-button-hidden')
			}

		},
		scrollUp (n) {
			let scrollEl = this.$el.querySelector('#' + this.htmlScrollId(n))
			if (scrollEl === undefined || scrollEl === null) {
				return
			}
			scrollEl.scrollTop = scrollEl.scrollTop - itemHeight
		},
		scrollDown (n) {
			let scrollEl = this.$el.querySelector('#' + this.htmlScrollId(n))
			if (scrollEl === undefined || scrollEl === null) {
				return
			}
			scrollEl.scrollTop = scrollEl.scrollTop + itemHeight
		},
	}
}

/*
	example: [
	{
		id: "one",
		icon: "person",
		title: "Title1",
		text: "text1",
		list: []
	},
	{
		id: "two",
		icon: "web",
		title: "Title2",
		text: "text2",
		list: [
			{
				id: "alpha",
				icon: "person",
				title: "Alpha",
				text: "text",
				list: []
			},
			{
				id: "bravo",
				icon: "person",
				title: "Bravo",
				text: "text",
				list: [
					{
						id: "two",
						icon: "web",
						title: "Title2",
						text: "text2",
						list: [
							{
								id: "alpha",
								icon: "person",
								title: "Alpha",
								text: "text",
								list: []
							},
							{
								id: "bravo",
								icon: "person",
								title: "Bravo",
								text: "text",
								list: []
							},

						]
					},

				]
			},

		]
	},
	{
		id: "three",
		icon: "lock",
		title: "Title2",
		text: "text2",
		list: []
	},
	{
		id: "four",
		icon: "lock",
		title: "Four",
		text: "text2",
		list: [
			{
				id: "alpha",
				icon: "person",
				title: "Alpha",
				text: "text",
				list: []
			},
			{
				id: "bravo",
				icon: "person",
				title: "Bravo",
				text: "text",
				list: []
			},
			{
				id: "charlie",
				icon: "person",
				title: "Charlie",
				text: "text",
				list: []
			},

		]
	},
	{
		id: "five",
		icon: "lock",
		title: "Five",
		text: "text2",
		list: [
			{
				id: "alpha",
				icon: "person",
				title: "Alpha",
				text: "text",
				list: []
			},
			{
				id: "bravo",
				icon: "person",
				title: "Bravo",
				text: "text",
				list: []
			},
			{
				id: "charlie",
				icon: "person",
				title: "Charlie",
				text: "text",
				list: []
			},
			{
				id: "delta",
				icon: "person",
				title: "Delta",
				text: "text",
				list: []
			},
			{
				id: "Echo",
				icon: "person",
				title: "Echo",
				text: "text",
				list: []
			},
			{
				id: "foxtrot",
				icon: "person",
				title: "Foxtrot",
				text: "text",
				list: []
			},

		]
	},
],
 */

</script>

<style lang="scss" scoped>

.tree-list {
	//
}

.tree-list .tree-container {
	position: relative;
	overflow-x: scroll;
	overflow-y: hidden;
	white-space: nowrap;
	-webkit-overflow-scrolling: touch;
	vertical-align: top;
	border-left: 1px solid #e0e0e0;
}

.tree-list .tree-container .scrolling-item {
	position: relative;
	vertical-align: top;
	display: inline-block;
	width: 240px;
	background-color: white;
	border: 1px solid #e0e0e0;
	border-left: 0;
	min-height: 40vh;
	height: 60vh;
	overflow-x: hidden;
	overflow-y: scroll;
}

/* ----------------------------------------------------------------
   this makes the scrollbar completely invisible
   ---------------------------------------------------------------- */
.tree-list .tree-container::-webkit-scrollbar, .tree-list .tree-container .scrolling-item::-webkit-scrollbar {
	display: none;
}

.tree-list .scroll-button {
	background-color: white;
	opacity: 0.8;
	float: left;
	display: block;
	//position: absolute;
	position: fixed;
	z-index: 5;
	height: 36px;
}

.tree-list .scroll-button-hidden {
	display: none;
	visibility: hidden;
}

//.tree-list .scroll-button-top {
//	top: 0;
//}
//
//.tree-list .scroll-button-bottom {
//	bottom: 0;
//}

.tree-list .sticky-relative {
	position: sticky;
	top: 0;
}

.tree-list .sticky-fixed {
	position: fixed;
	top: 0;
}

.tree-list .sticky-avoid-top-bar {
}

.tree-list .debug-box {
	margin-left: 20em;
	position: fixed;
	top: 200px;
	background-color: #eeeeee;
	border: 1px solid red;
	padding: 1em;
	min-width: 10em;
}

</style>
