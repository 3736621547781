<template>
	<fieldset :id="id">
		<component
			:is="item.component"
			v-for="item in items"
			v-show="!item.hidden"
			v-bind:id="item.id"
			:key="item.id"
			v-bind:item="item"
			v-on:resized="resized"
			v-on:form-callback="emitCallback"
		/>
	</fieldset>
</template>

<script>

import FormEditableList from './FormEditableList'

export default {
	components: {
		FormHeading: () => import('./FormHeading'),
		FormDivider: () => import('./FormDivider'),
		FormCard: () => import('./FormCard'),
		FormBasicInput: () => import('./FormBasicInput'),
		FormBasicSelect: () => import('./FormBasicSelect'),
		FormAddress: () => import('./FormAddress'),
		FormColumns: () => import('./FormColumns'),
		FormColumnSelector: () => import('./FormColumnSelector'),
		FormCheckbox: () => import('./FormCheckbox'),
		FormBasicEditor: () => import('./FormBasicEditor'),
		FormBasicButton: () => import('./FormBasicButton'),
		FormMultiChange: () => import('./FormMultiChange'),

		// TODO FIXME Unable to load FormEditableList asynchronously
		FormEditableList,
	},
	props: [
		'id',
		'items',
	],
	methods: {
		resized (event) {
			this.$emit('resized', event)
		},
		emitCallback (args) {
			this.$emit('form-callback', args)
		},
	},
}

</script>

<style scoped>

.padded-control {
	padding: 0.5em 1em;
}

.padded-control .mdc-select {
	margin-bottom: 1em;
}

fieldset {
	border: 0;
	padding: 0;
	margin: 0;
}

</style>
